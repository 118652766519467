<template>
  <div class="main_style">
    <div class="header">
      <div class="header-text">
        <p class="header-title">“汇存”区块链电子数据存证平台</p>
        <p class="header-desc">
          “汇存”区块链电子数据存证平台是上海市徐汇公证处与北京众享科技共同打造的基于区块链技术的专业存证产品，将公证机构的公证力与区块链的技术可信相结合，让电子证据成为被法院认可的有效证据。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">方案介绍</div>
      <div class="intro-content">
        <p>
          “汇存”区块链电子数据存证平台是上海市徐汇公证处与众享科技共同打造的基于区块链技术的专业存证产品，将公证机构的公证力与区块链的技术可信相结合，让电子证据成为被法院认可的有效证据。<br/><br/>“汇存”区块链电子数据存证平台一经问世，便引起了媒体及各界的广泛关注。该平台以国家法定公证机关为主体，为全国各大律师事务所和各行业客户提供电子数据的取证、存证、公证等服务。平台利用区块链技术保障电子证据真实且不可篡改，在降低电子数据存证及认定成本、提升判决效率等方面起到了重要作用。
        </p>
      </div>
    </div>
     <!-- 核心功能 -->
     <div class="function">
      <div class="commom-title">核心功能</div>
      <div class="content">
        <el-row :gutter="24" class="grid">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/solution/justice/function-1.png"
                />
              </div>
              <div class="title">随时随地、多方式取证</div>
              <div class="text">
                不限时间，不限地点，不限网络环境，支持拍照、摄像、录屏、录音、网页、文件等多种方式取证。
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                src="@/assets/img/solution/justice/function-2.png"
                />
              </div>
              <div class="title">秒级生成电子“身份证”</div>
              <div class="text">
                手机APP操作，一键自动生成，秒级出证；区块链技术保证加公证处背书，进一步提升了电子“身份证”的证据力。
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <div class="img">
                <img
                src="@/assets/img/solution/justice/function-3.png"
                />
              </div>
              <div class="title">线上申请公证&线下办理</div>
              <div class="text">
                手机APP线上预约，提交公证申请；线下公证处专人接待，不排队，贴心服务。
              </div>
            </div>
          </el-col>
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                src="@/assets/img/solution/justice/function-4.png"
                />
              </div>
              <div class="title">电子数据在线验证</div>
              <div class="text">
                在线输入文件与对应哈希，随时验证本地文件真实性。后台自动对比验证文件与链上信息，验证通过则证明公证处已核验本地文件未被篡改。
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                src="@/assets/img/solution/justice/function-5.png"
                />
              </div>
              <div class="title">底层区块链清晰可见</div>
              <div class="text">
                区块链浏览器提供了可视化浏览与查询区块链信息的入口，便于用户更高效、直观地查询链上存储的数据，让区块链可被清晰地看见。
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">方案架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/solution/justice/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">方案优势</div>
      <div class="advantage-content">
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/solution/justice/advantage-1.png"
                />
              </div>
              <div class="title">合法合规</div>
              <div class="text">
                存证平台运营主体为国家法定公证处，存证发起方需通过实名验证、活体检测，确认身份合法，电子数据采集存证过程规范化和制度化，同时引入监管机构主动监督，多管齐下，保证电子数据采集使用合规合法，证据力强。
              </div>
            </div></el-col
          >
          <el-col :span="12"><div class="grid-content">
              <div class="img">
                <img
								src="@/assets/img/solution/justice/advantage-2.png"
                />
              </div>
              <div class="title">安全可靠</div>
              <div class="text">
                采用哈希校验、电子签名、时间戳、GPS定位和区块链等技术，保障平台运行环境安全、手机系统安全、网络传输安全和数据安全。
              </div>
            </div></el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12"><div class="grid-content">
              <div class="img">
                <img
								src="@/assets/img/solution/justice/advantage-3.png"
                />
              </div>
              <div class="title">高效便捷</div>
              <div class="text">
                电子数据移动采集、存证、出证，突破时间、地域、网络限制，操作便捷，大大提高诉讼效率。
              </div>
            </div></el-col>
          <el-col :span="12"><div class="grid-content">
              <div class="img">
                <img
								src="@/assets/img/solution/justice/advantage-4.png"
                />
              </div>
              <div class="title">场景众多</div>
              <div class="text">
                使用场景多样，涵盖用户存证、合同保全、知识产权保护、群防群治等多个领域。同时满足个人和企业的存证需求，为个人用户提供如个人文件材料、原创内容等的存证，也可以为企业级用户提供服务，如合同、文件等存证。
              </div>
            </div></el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12"><div class="grid-content">
              <div class="img">
                <img
								src="@/assets/img/solution/justice/advantage-5.png"
                />
              </div>
              <div class="title">自主可控</div>
              <div class="text">
                上层应用层由公证处运营维护，底层采用众享比特自主研发的ChainSQL区块链平台，支持国密算法，已获商用密码产品型号证书。
              </div>
            </div></el-col>
          <el-col :span="12"><div class="grid-content">
              <div class="img">
                <img
								src="@/assets/img/solution/justice/advantage-6.png"
                />
              </div>
              <div class="title">高扩展性</div>
              <div class="text">
                提供标准化接口，无缝对接第三方系统，可满足企业级用户的自动化存证需求。
              </div>
            </div></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  mounted() {
     // 页面回退顶部
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/solution/justice/bg.png")
      center/cover no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0,0,0,0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    padding-bottom: 70px;
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
  .function {
    background: #f4f7fc;
    .content {
      @extend .content-width;
      padding-bottom: 20px;
     
      .grid {
        padding-bottom: 26px;
        .grid-content {
          height: 352px;
          margin-bottom: 24px;
          background: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 42px 24px 0px;
          .img {
            width: 124px;
            height: 124px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            padding: 24px 0px;
            font-size: 22px;
            font-family: "PingFangSC-Medium";
            color: rgba(0, 0, 0, 0.85);
            line-height: 30px;
          }
          .text {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 22px;
            text-align: center;
          }
        }
      }
    }
  }
  .framework {
    padding-bottom: 70px;
    .framework-content {
      @extend .content-width;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .grid-content {
        height: 370px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 40px 48px 0px 40px;
        .img {
          width: 124px;
          height: 124px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          padding: 24px 0px;
          font-size: 22px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }
      }
    }
  }
}
</style>
